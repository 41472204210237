@import '../../../../assets/styles/variables';

.MemberInfo {
	&__form {
		min-height: calc(100% - 75px);

		&__section {
			display: flex;
			flex-direction: column;
			padding: 14px 24px 24px 24px;
			gap: 24px;
			border-bottom: 1px solid var(--darkGrey);

			&:last-of-type {
				border-bottom: none;
			}

			.section_dual {
				padding: 0px;
				display: grid;
				grid-template-columns: 60% auto;

				@include breakpoint('desktop-xs', 'max') {
					display: flex;
					flex-direction: column-reverse;
					gap: 24px;
				}
				.payment-status{
					text-transform: capitalize;
				}
			}

			.FormDisplayGroup {
				margin: 0;
			}

			&__one {
				display: grid;
				grid-template-columns: auto auto;
				align-items: center;

				&__left {
					display: flex;
					flex-direction: column;
					gap: 24px;

					@include breakpoint('desktop-xs', 'max') {
						// margin-top: 108px;
						margin-top: -24px;
					}

					&>div:nth-child(2) {
						@include breakpoint('desktop-xs', 'max') {
							margin-top: 108px;
						}
					}
				}

				&__right {
					display: flex;
					flex-direction: column;
					gap: 24px;

					&>div:last-of-type {
						display: flex;
						position: relative;
						gap: 87px;

						&>div:nth-child(1) {
							@include breakpoint('desktop-xs', 'max') {
								position: absolute;
								top: 56px;
							}
						}

						&>div:nth-child(2) {
							@include breakpoint('desktop-xs', 'max') {
								position: absolute;
								top: 114px;
							}
						}

						@include breakpoint('desktop-xs', 'max') {
							display: flex;
							flex-direction: column;
							gap: 24px;
						}
					}
				}
			}

			&.three-col {
				& .section-about-us {
					padding: 0px;
					max-width: 80%;

					.FormDisplayGroup {
						span {
							line-height: 20.8px;
						}
					}
				}
			}

			&.frequently {
				@include breakpoint('mobile-xl', 'max') {
					// display: none;
				}

				.FormDisplayGroup {
					&:first-of-type {
						flex-basis: 100%;
					}

					&__value {
						display: flex;
						flex-direction: column;
						gap: 8px;
						max-height: 265px;
						overflow-y: scroll;

						&::-webkit-scrollbar {
							width: 8px;
						}

						&::-webkit-scrollbar-thumb {
							background-color: var(--darkGrey);
							/* Color pin */
							border-radius: 6px;
						}

						&::-webkit-scrollbar-track {
							background-color: transparent;
							/* backgorund scroll transparent */
						}

						& .FromDisplayGroupFrequently {
							&__section {
								display: grid;
								grid-template-columns: 60% auto;
								align-items: center;

								& .date-time {
									display: flex;
									align-items: center;
									gap: 4px;
									color: var(--grey);
								}
							}
						}
					}
				}
			}

			.isReferral {
				display: flex;

				&__option {
					padding: 5px 22px;
					background: var(--secondaryBlack);
					border: 1px solid var(--white);
					color: var(--white);
					user-select: none;

					@include breakpoint('desktop-xs', 'min') {
						padding: 5px 30px;
					}

					&.active {
						background: var(--white);
						color: var(--black);
					}
				}
			}

			&.buttons-section {
				display: grid;
				grid-template-columns: 54.5% auto;

				@include breakpoint('desktop-xs', 'max') {
					justify-content: flex-start;
					justify-content: space-between;
				}

				.section-btn-2 {
					padding: 0px;
					display: flex;
					align-items: center;
				}

				.buttonWrapper {
					width: 100%;

					&__btn {
						padding: 0;
						border: none;
						background-color: transparent;
					}

					&.sent-btn {
						max-width: fit-content;

						@include breakpoint('desktop-xs', 'max') {
							max-width: fit-content;
						}

						& div:first-of-type {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							align-self: stretch;
							gap: 8px;

							@include breakpoint('desktop-xs', 'max') {
								padding: 0;

								& span {
									font-size: 14px;
								}
							}
						}
					}

					&.edit-btn {
						flex-basis: 90%;

						@include breakpoint('desktop-xs', 'max') {
							max-width: fit-content;
						}

						& div:first-of-type {
							display: flex;
							padding: 0 10px;
							align-items: center;
							justify-content: flex-start;
							gap: 10px;
							align-self: stretch;

							@include breakpoint('desktop-xs', 'max') {
								padding: 0;

								& span {
									font-size: 14px;
								}
							}
						}
					}
				}

				& .options {
					display: flex;
					align-items: center;
					padding: 0 14px;
					gap: 10px;

					&:hover {
						cursor: pointer;
					}

					@include breakpoint('desktop-xs', 'max') {
						padding: 0;

						& span {
							display: none;
						}
					}

					.ThreeDotsOptionsMenuWrapper {
						.ThreeDotsOptionsMenu {
							width: 205px;
						}
					}

					&__item-delete {
						display: flex;
						align-items: center;
						color: var(--primary);
						gap: 10px;
						text-decoration: none;
						user-select: none;
						border-top: 1px solid var(--darkGrey);
						background: transparent;
						cursor: pointer;
						border: none;

						&:last-of-type {
							margin: 0;
						}
					}
					&__item-close {
						display: flex;
						align-items: center;
						color: var(--white);
						padding: 0;
						gap: 10px;
						text-decoration: none;
						user-select: none;
						background: transparent;
						cursor: pointer;
						border: none;

						&:last-of-type {
							margin: 0;
						}
					}
				}
			}
		}
	}
}
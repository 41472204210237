@import '../../assets/styles/variables';

.VerifyMembership {
	&__logo {
		width: 100vw;
		padding: 26px 0px;
		border-bottom: 2px solid var(--darkGrey);
		picture {
			display: block;
			max-width: 100px;
			margin: 0 auto;
			img {
				width: 100%;
			}
		}
	}

	&__col {
		padding: 26px;

      @include breakpoint('tablet-xs', 'min') {
         max-width: 260px;
         margin: 0 auto;
      }

      .FormDisplayImage {
			margin-bottom: 18px;
		}

		&__left {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;

			.FormDisplayGroup {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin: 18px 0px;
				&__label {
					opacity: 0.5;
					font-size: 14px;
					font-weight: 300;
				}

				&__value {
					&.__green {
						color: var(--green);
					}
					&.__orange {
						color: #f2a33c;
					}
					&.__red {
						color: var(--primary);
					}
				}
			}
		}
	}
}

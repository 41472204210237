.inputField {
	position: relative;
	text-align: left;

	&__label {
		display: block;
		margin: 0 0 10px;
		font-size: 15px;
		line-height: 1;
	}

	&__input {
		display: block;
		padding: 8px 10px;
		box-sizing: border-box;
		width: 100%;
		font-size: 16px;
		line-height: 1;
		background-color: var(--black);
		color: #cccccc;
		border: 1px solid var(--darkGrey);
	}

	&__input-error {
		color: var(--primary);
	}

	&__togglePasswordVisibility {
		position: absolute !important;
		bottom: 0;
		right: 0;
		color: var(--grey) !important;
	}
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
	/* Firefox */
}
@import '../../assets/styles/variables';

.PaymentForMembership {
	&__form {
		position: relative;
		.inputField {
			margin-bottom: 16px;
		}
		.inputField--group {
			display: flex;
			flex-wrap: wrap;
			.inputField {
				flex: 1;
				margin: 0;
				&:first-of-type {
					margin-right: 16px;
				}
			}
		}
		.inputField__wrapper {
			display: block;
			padding: 8px 10px;
			box-sizing: border-box;
			width: 100%;
			font-size: 16px;
			line-height: 1;
			background-color: var(--black);
			color: #cccccc;
			border: 1px solid var(--darkGrey);
		}
	}
	&__message {
		color: #ff7373;
		margin-top: 16px;
	}
	&__title {
		color: var(--white);
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin: 24px 0 16px;
		text-align: left;
		width: 100%;
	}

	&__btn {
		background: var(--white);
		border: 0;
		box-shadow: 0px 2px 4px 0px rgba(52, 60, 68, 0.16),
			0px 0px 1px 0px rgba(117, 131, 142, 0.04);
		color: var(--black);
		cursor: pointer;
		font-size: 17px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin: 16px 0 0;
		padding: 12px 26px;
		width: 100%;
		transition: all 0.2s ease-in-out;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;

		&:hover {
			opacity: 0.7;
		}
		&:disabled {
			opacity: 0.5;
		}

		&--alt {
			background-color: var(--black);
			font-weight: 400;
			font-size: 17px;
			line-height: 1;
			border: 1px solid var(--white);
			color: var(--white);
		}
	}
	&__btn--loading .button__text {
		visibility: hidden;
		opacity: 0;
	}

	&__btn--loading::after {
		content: '';
		position: absolute;
		width: 16px;
		height: 16px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		border: 4px solid transparent;
		border-top-color: var(--black);
		border-radius: 50%;
		animation: button-loading-spinner 1s ease infinite;
	}

	@keyframes button-loading-spinner {
		from {
			transform: rotate(0turn);
		}

		to {
			transform: rotate(1turn);
		}
	}
}

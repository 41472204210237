.InputSearch {
	position: relative;
	z-index: 0;

	.inputField {
		&__input {
			padding-left: 36px;
		}
	}

	.InputSearch__icon {
		position: absolute;
		z-index: 1;
		top: 55%;
		transform: translateY(-50%);
		left: 2px;
	}
}

.InputReferredBy {
	position: relative;
	flex-basis: 50%;

	.inputField {
		&__input {
			padding-left: 48px;
		}
	}

	&__icon {
		position: absolute;
		top: 72%;
		transform: translateY(-50%);
		left: 10px;
	}
}

.FormDisplayImage {
    width: 200px;
    height: 200px;
    border: 1px solid var(--grey);
    overflow: hidden;

    &__img {
        position: relative;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;

        &.disabled {
            filter: grayscale(100%);
            opacity: 0.6;
            cursor: not-allowed;
        }
    }
}
@import '../../assets/styles/variables';

.Admins {
	&__menu {
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include breakpoint('mobile-xl', 'max') {
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;
			margin: 24px 0 70px 0;
		}

		&__add {
			width: 141px;

			.buttonWrapper {
				&__btn {
					height: 36px;
					padding: 8px 22px;
					border: 1px solid var(--darkGrey);
					background-color: var(--secondaryBlack);
				}
			}
		}

		&__search {
			@include breakpoint('mobile-xl', 'max') {
				min-width: 100%;
			}
			@include breakpoint('desktop-xs', 'min') {
				min-width: 250px;
			}
			@include breakpoint('desktop-s', 'min') {
				min-width: 310px;
			}
			@include breakpoint('desktop-m', 'min') {
				min-width: 346px;
			}
		}
	}
}

@import '../../../assets/styles/variables';
.ApproveModal {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px 25px;
		h3 {
			margin: 0;
			line-height: 1;
			font-size: 22px;
			font-weight: 400;
		}
	}
	&__content {
		padding: 20px 25px;
		border-top: 1px solid var(--darkGrey);
		p {
			font-size: 15px;
			line-height: 1.5;
		}
	}
	&__buttons {
		padding: 20px 25px;
		border-top: 1px solid var(--darkGrey);
		@include breakpoint('desktop-xs', 'min') {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		&__button {
			margin-bottom: 10px;
			&--not--approve {
				margin-bottom: 0px;
				@include breakpoint('desktop-xs', 'min') {
					margin-bottom: 0;
				}
				button {
					color: var(--primary);
					border-color: var(--primary);
				}
			}
			.NobleIcon {
				margin: 0 10px 0 0;
				transform: translateY(2.5px);
			}
		}
	}
}

.FormDisplayGroup {
	display: flex;
	flex-direction: column;
	margin: 25px 0 0;
	&:first-of-type {
		margin: 0;
	}
	&__label {
		margin: 0 0 8px;
		opacity: 0.5;
		font-size: 14px;
		font-weight: 300;
	}
	&__value {
		max-width: 95%;
	}
	&__link {
		cursor: pointer;
		word-wrap: break-word;
	}
}

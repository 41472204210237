@import '../../assets/styles/variables';
.Applications {
	&__menu {
		width: 100%;
		@include breakpoint('desktop-xs', 'min') {
			display: flex;
			align-items: center;
		}
		.OptionsButtons {
			margin: 0 0 0;
			@include breakpoint('desktop-xs', 'min') {
				margin: 0 0 0;
			}
		}
		&__filter {
			margin: 20px 0 10px;
			@include breakpoint('desktop-xs', 'min') {
				margin: 0 15px 0 auto;
				min-width: 200px;
				max-width: 220px;
			}
		}
		&__search {
			border-radius: 0;
			@include breakpoint('desktop-xs', 'min') {
				min-width: 380px;
			}
			@include breakpoint('desktop-xs', 'min') {
				min-width: 250px;
			}
			@include breakpoint('desktop-s', 'min') {
				min-width: 310px;
			}
			@include breakpoint('desktop-m', 'min') {
				min-width: 346px;
			}
		}
	}
}

@import '../../../assets/styles/variables';
.ApplicationsList {
	margin: 20px 0 0;
	@include breakpoint('desktop-xs', 'min') {
		margin: 20px 0 0 -40px;
		width: calc(100% + 80px);
	}
	&__noResults {
		padding: 0 40px;
	}
	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid var(--darkGrey);
		padding: 15px 10px;
		line-height: 1;
		cursor: pointer;
		@include breakpoint('desktop-xs', 'min') {
			padding: 15px 40px;
		}
		&.active,
		&:hover {
			background-color: var(--darkGrey);
		}
		h4 {
			margin: 0 0 5px;
			font-size: 20px;
			font-weight: 400;
		}
		small {
			font-size: 14px;
			font-weight: 400;
		}
		&--name {
			small {
				span {
					display: inline-block;
					margin-left: 1ch;
					color: var(--green);
					font-weight: 300;
				}
			}
		}
		&--date {
			small {
				opacity: 0.5;
			}
		}
	}
}

.App {
	text-align: center;
	justify-content: center;
	display: flex;
	height: 100vh;

	.inputError {
		display: block;
		margin: 5px 0 0;
		font-size: 13px;
		line-height: 15px;
		color: var(--primary);
		text-align: right;
	}
}

@import '../../../assets/styles/variables';

@mixin customMargin {
	margin: 28px 0 0;

	&:nth-child(1) {
		margin: 0;
	}
}

.UserAdminModal {
	display: flex;
	flex-direction: column;
	height: 100%;

	&__title {
		margin: 0;
		padding: 24px;
		font-size: 28px;
		font-weight: 400;
		line-height: 1;
		border-bottom: 1px solid var(--darkGrey);
	}

	&__form {
		padding: 28px 38px 0 28px;

		&__section {
			&__input-error {
				display: block;
				margin: 5px 0 0;
				font-size: 13px;
				line-height: 15px;
				color: var(--primary);
				text-align: right;
			}
		}

		.inputField {
			@include customMargin;

			&__input {
				background-color: var(--secondaryBlack);
			}
		}

		.inputPhoneNumber {
			@include customMargin;

			&__input {
				background-color: var(--secondaryBlack);
			}
		}
	}

	&__buttons_section {
		padding: 0 38px 0 28px;
		margin-top: auto;
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;

		.buttonWrapper {
			&__btn {
				font-weight: 700;
			}

			&.add-admin {
				width: 250px;

				@include breakpoint('mobile-xl', 'max') {
					width: 100%;
				}
			}

			&.cancel {
				width: 200px;

				@include breakpoint('mobile-xl', 'max') {
					width: 100%;
				}
			}
		}

		@include breakpoint('mobile-xl', 'max') {
			flex-direction: column;
			gap: 14px;
			padding-bottom: 28px;
		}
	}
}
@import '../../assets/styles/variables';

.Members {
	.buttonWrapper__btn {
		transition: all 0.2s ease-in;
		&:hover {
			color: #ffffff75;
			border-color: #ffffff75;
		}
	}
	&__btn-export {
		display: flex;
		gap: 15px;
		align-items: center;
		justify-content: center;
		color: #f7f7f7;
		span {
			font-family: var(--fontFamily);
			font-size: 16px;
			font-weight: 400;
			line-height: 19.2px;
			text-align: center;
		}
	}

	&__menu {
		margin-bottom: 10px;
		margin-top: 20px;
		@include breakpoint('desktop-xs', 'min') {
			display: flex;
			align-items: center;
		}

      &__mobile {
         display: flex;
         flex-direction: column;
         gap: 14px;
      }

		.OptionsButtons {
			margin: 0 0 0;
			@include breakpoint('desktop-xs', 'min') {
				margin: 0 0 0;
			}
		}
		&__filter {
			display: flex;
			flex-direction: column;
			gap: 14px;
			margin: 20px 0 14px;

			@include breakpoint('desktop-xs', 'min') {
				flex-direction: row;
				align-items: center;
				margin: 0 0 0 auto;
				min-width: 374px;
				padding-left: 15px;
			}

			&__tiers {
				@include breakpoint('desktop-xs', 'min') {
					min-width: 167px;
				}
				@include breakpoint('desktop-s', 'min') {
					min-width: 167px;
				}
			}

			&__status {
				@include breakpoint('desktop-xs', 'min') {
					.NobleSelect {
						min-width: 167px;
					}
				}
				@include breakpoint('desktop-s', 'min') {
					.NobleSelect {
						min-width: 167px;
					}
				}
			}

			&__member__types {
				@include breakpoint('desktop-xs', 'min') {
					min-width: 167px;
				}
				@include breakpoint('desktop-s', 'min') {
					min-width: 167px;
				}
			}

			&__grms {
				@include breakpoint('desktop-xs', 'min') {
					min-width: 167px;
				}
				@include breakpoint('desktop-s', 'min') {
					min-width: 167px;
				}
			}
		}

      &__btn_add_member {
         & .buttonWrapper {
            &__btn {
               height: 38px;
               padding: 8px 22px;
               border: 1px solid var(--darkGrey);
               background-color: var(--secondaryBlack);

               & .contain_add_member {
                  display: flex;
                  gap: 15px;
                  align-items: center;
                  justify-content: center;
                  color: #f7f7f7;
                  font-family: var(--fontFamily);
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19.2px;
                  text-align: center;
               }
            }
         }
      }

		&__search {
			border-radius: 0;
			@include breakpoint('desktop-xs', 'min') {
				min-width: 250px;
			}
			@include breakpoint('desktop-s', 'min') {
				min-width: 310px;
			}
			@include breakpoint('desktop-m', 'min') {
				min-width: 346px;
			}
		}

		&__search__two {
			border-radius: 0;
			width: 100%;
			@include breakpoint('desktop-xs', 'min') {
				min-width: 250px;
			}
			@include breakpoint('desktop-s', 'min') {
				min-width: 310px;
			}
			@include breakpoint('desktop-m', 'min') {
				min-width: 346px;
			}
		}
	}
}

.DashboardLayout {
	&__content {
		height: 100%;
		width: -webkit-fill-available;
		text-align: left;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 0 5%;
		@include breakpoint('desktop-xs', 'min') {
			padding: 0 40px;
		}
		&__header {
			@include breakpoint('desktop-xs', 'min') {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			&__title {
				margin: 18px 0 5px;
				font-size: 34px;
				font-weight: 400;
				line-height: 1.4;
				@include breakpoint('desktop-xs', 'min') {
					margin: 30px 0 5px;
				}
			}
			&__total {
				font-size: 14px;
				font-weight: 300;
				@include breakpoint('desktop-xs', 'min') {
					opacity: 0.5;
				}
			}
		}
	}
}

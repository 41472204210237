@import '../../../../assets/styles/variables';

.AddInvestors {
	min-width: 90%;
	display: flex;
	flex-direction: column;
	height: 90vh;

	&__form {
		min-height: calc(100% - 75px);
		flex-grow: 1;

		&__section {
			display: flex;
			flex-direction: column;
			padding: 14px 36px 24px 24px;
			gap: 18px;

			&.separator {
				border-bottom: 1px solid var(--darkGrey);
			}

			.inputField {
				&__input {
					background-color: transparent;
					color: var(--white);

					&:disabled {
						background-color: var(--darkGrey);
						cursor: not-allowed;
						color: var(--white);
					}
				}
				&__input-error {
					color: var(--primary);
					border-color: var(--primary);
				}

				&__label {
					&__tierType {
						font-weight: 300;
						font-size: 15px;
						color: var(--white);
						opacity: 1;
						letter-spacing: 0.55px;
						margin-bottom: 0px;
					}
				}
			}

			.inputPhoneNumber {
				&__input {
					background-color: transparent;
				}
			}

			.referral-section {
				display: flex;
				flex-direction: column;
				gap: 24px;
				padding: 0px;
				margin-top: 20px;

				.FormDisplayGroup {
					&__label {
						font-size: 14px;
						font-weight: 300;
						color: var(--white);
						opacity: 1;
						letter-spacing: 0.55px;
						margin-bottom: 10px;
					}
				}

				.FormDisplayGroupReferral {
					display: flex;
					flex-direction: column;
					gap: 10px;
					padding: 0px;
					margin-bottom: 10px;

					.isReferral {
						display: flex;
						padding: 0px;

						&__option {
							padding: 5px 18px;
							background: var(--secondaryBlack);
							border: 1px solid var(--white);
							color: var(--white);
							border-radius: 0;
							font-size: 14px;
							font-weight: 400;
							line-height: 1.4;
							text-align: center;
							cursor: pointer;
							transition: all 0.3s ease;
							user-select: none;

							@include breakpoint('desktop-xs', 'min') {
								padding: 5px 18px;
							}

							&.active {
								background: var(--white);
								color: var(--black);
							}
						}
					}
				}
			}

			.section-dual {
				display: grid;
				grid-template-columns: 47% auto;
				align-items: center;
				gap: 24px;

				@include breakpoint('desktop-xs', 'min') {
					grid-template-columns: 47% auto;
				}

				@include breakpoint('mobile-xl', 'max') {
					grid-template-columns: 100%;
				}

				& .inputField_containerSelect {
					display: flex;
					flex-direction: column;
					gap: 8px;
				}
			}

			&.buttons_section {
				padding: 14px 36px 24px 24px;
				gap: 24px;

				@include breakpoint('desktop-xs', 'min') {
					justify-content: space-between;
					height: max-content;
					align-items: end;
					align-self: flex-end;
					flex-direction: row;
				}

				.buttonWrapper {
					&__btn {
						font-weight: 700;

						&:disabled {
							background-color: var(--darkGrey);
							cursor: not-allowed;
							color: var(--white);
							opacity: 0.5;
							pointer-events: none;
						}
					}

					&.save {
						@include breakpoint('desktop-xs', 'min') {
							width: 250px;
						}

						@include breakpoint('mobile-xl', 'max') {
							width: 100%;
						}
					}

					&.cancel {
						@include breakpoint('desktop-xs', 'min') {
							width: 200px;
						}

						@include breakpoint('mobile-xl', 'max') {
							width: 100%;
						}
					}
				}
			}
		}
	}
}

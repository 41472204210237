@import '../../../assets/styles/variables';

.ConfirmBoxPicker {
    background: var(--black) !important;
    width: 10%;
    display: inline-block;
    bottom: 2%;
    z-index: 999;
}

.CancelButtonPicker {
    background: var(--black);
    border-style: solid;
    color: var(--white);
    min-width: 10%;
    height: 6vh;
    font-size: 17px;
    line-height: 19.55px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--white);
    font-weight: 700;

    @include breakpoint('desktop-xs', 'max') {
        min-width: 43%;
    }

}

.ConfirmingButtonPicker {
    background-color: var(--white);
    border-style: solid;
    border-color: var(--white);
    color: var(--black);
    min-width: 10%;
    height: 6vh;
    font-size: 17px;
    line-height: 19.55px;
    margin-right: 2%;
    font-weight: 700;

    @include breakpoint('desktop-xs', 'max') {
        min-width: 43%;
    }
}
.InputRadio {
	display: flex;
	align-items: center;
	gap: 8px;
	&__input {
		position: relative;

		input[type='radio'] {
			display: none;
		}
	}

	input[type='radio']:checked ~ &__btn {
		background-color: var(--white);
		border-color: var(--darkGrey);
		&:hover {
			cursor: pointer;
			background-color: var(--darkGrey);
			border-color: var(--white);
		}
		&:active {
			background-color: var(--grey);
			border-color: var(--darkGrey);
			box-shadow: var(--black);
		}
		&.disabled {
			background-color: var(--grey);
			border-color: var(--darkGrey);
		}
		span {
			position: absolute;
			height: 6px;
			width: 6px;
			top: 35%;
			left: 35%;
			background-color: var(--black);
			border-radius: 50%;
			opacity: 1;
		}
	}

	&__btn {
		width: 18px;
		height: 18px;
		background-color: var(--secondaryBlack);
		border: 1px solid var(--grey);
		border-radius: 80px;
		span {
			opacity: 0;
			transition: all ease 0.3s;
		}
		&:hover {
			cursor: pointer;
			border-color: var(--darkGrey);
		}
		&:focus {
			border-color: var(--darkGrey);
			box-shadow: var(--black);
		}
		&:active {
			background-color: var(--grey);
			border-color: var(--darkGrey);
			box-shadow: vat(--grey);
		}
		&.disabled {
			background-color: var(--grey);
			border-color: var(--grey);
		}
	}
}

@import '../../../assets/styles/variables';
.InputDisabled {
	position: relative;

	& .inputField {
		& .inputField__input {
			color: var(--white);
		}
	}

	.InputDisabled__icon {
		position: absolute;
		top: 70%;
		transform: translateY(-50%);
		right: 14px;
	}

	.InputDisabled__icon__relative {
		position: absolute;
		top: 40%;
		transform: translateY(-70%);
		right: 14px;	
		@include breakpoint('mobile-xl', 'max') {
			top: 45%;
			transform: translateY(-70%);
		}
	}
}
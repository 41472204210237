.MuiTimeBox {
	min-width: 100%;
	justify-content: center;
	text-align: center;
	display: flex;
	align-items: center;
	margin-bottom: 35%;
    z-index: 1;
    font-family: "sofiapro-light";
    font-weight: 400;
    @media only screen and (max-height: 668px) and (min-height: 500px) {
        position: fixed;
        top: 70%;
        left: 50%;
        transform: translateX(-50%);
	}
    @media only screen and (max-height: 850px) and (min-height: 669px) {
        position: fixed;
        top: 68%;
        left: 50%;
        transform: translateX(-50%);
	}
	@media only screen and (max-height: 897px) and (min-height: 850px) {
        position: fixed;
        top: 68%;
        left: 50%;
        transform: translateX(-50%);
	}
    @media only screen and (min-height: 897px) {
        position: fixed;
        top: 68%;
        left: 50%;
        transform: translateX(-50%);
	}
}

.MuiTimeMiniBox {
    width: 100%;
    max-width: 310px;
    color: var(--white);
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    line-height: 22px;
}

.MuiTimeTimeBox {
    min-width: 70%;
    text-align: left;
    display: flex;
    align-items: center;
}

.MuiTimeHourBox {
    background-color: var(--black);
    min-width: 81px;
    height: 34px;
    display: flex;
    justify-content: right;
    text-align: center;
    align-items: center;
}

@import '../../../../assets/styles/variables';

@mixin customMarginAndText {
	margin: 24px 0 24px 0;
	display: flex;
	align-items: flex-start;
	gap: 10px;
	width: 552px;

	@include breakpoint('mobile-xl', 'max') {
		max-width: 327px;
	}

	&__text {
		display: flex;
		gap: 10px;
		flex-direction: column;
		& span {
			color: var(--grey);
		}
	}
}

.SectionRadioButton {
	margin: 24px 0 24px 0;
	&__label {
		margin-bottom: 24px;
	}

	&__superadmin {
		@include customMarginAndText;
	}

	&__manager {
		@include customMarginAndText;
	}
}

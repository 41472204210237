.ThankYou {
	&__options {
		display: flex;
		margin-top: 16px;

		& > *:not(:last-child)  {
			margin-right: 16px;
		}
	}

	img {
		width: 38px;
	}
	h2 {
		font-size: 26px;
		font-weight: 300;
		line-height: 25px;
		letter-spacing: 0.05em;
		margin: 16px 0;
	}
	p {
		font-size: 18px;
		font-weight: 300;
		line-height: 23px;
		letter-spacing: 0em;
		text-align: center;
	}
}

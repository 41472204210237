@import '../../assets/styles/variables';

.Login {
	&__form {
		width: 100%;
	}
	.inputField {
		margin: 16px 0 0;
		&:nth-child(1) {
			margin: 0;
		}
	}
	&__forgot {
		display: block;
		margin: 10px 0 0;
		color: #cccccc;
		font-size: 13px;
		line-height: 1;
		text-decoration: none;
		text-align: right;
	}
	.buttonWrapper {
		margin: 20px 0 0;
	}
}

@import '../../assets/styles/variables';

@mixin customMargin {
    margin: 16px 0 0;

    &:nth-child(1) {
        margin: 0;
    }
}

.UserProfile {
    &__form {
        margin: 24px 0 0;
        max-width: 355px;

        &__section {
            &__input-error {
                display: block;
                margin: 5px 0 0;
                font-size: 13px;
                line-height: 15px;
                color: var(--primary);
                text-align: right;
            }
        }
    }

    .inputField,
    .inputEditProfile,
    .inputPhoneNumber,
    .customDatePicker {
        @include customMargin;

        >label {
            display: block;
            margin: 0 0 10px;
            font-size: 15px;
            line-height: 1;
        }
    }

    .buttonWrapper {
        margin: 24px 0 0;
        max-width: 250px;

        @include breakpoint('mobile-xl', 'max') {
            max-width: 100%;
        }
    }
}
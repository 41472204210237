.DashboardUserProfile {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	&__box {
		margin: 20px 8px 20px 20px;
		display: flex;
		justify-content: space-between;
	}
	&__info {
		&__username {
			display: block;
			color: var(--white);
			text-decoration: none;
			text-align: left;
			font-weight: 300;
		}
		&__useremail {
			display: block;
			font-size: 14px;
			font-weight: 300;
			text-align: left;
			opacity: 0.5;
		}
	}
	&__options {
		position: relative;
		display: flex;
		align-items: center;
		.ThreeDotsOptionsMenu {
			width: 175px;
		}
	}
	&__settings {
		&:hover {
			cursor: pointer;
			background-color: var(--darkGrey);
		}
		padding: 16px 20px;
		&.active {
			background-color: var(--darkGrey);
		}
	}
}

@import '../../../assets/styles/variables';
.ReactModal {
	&__close {
		position: absolute;
		right: 5%;
		top: 28px;
		z-index: 100;
		cursor: pointer;
	}
}

.NobleIcon {
	display: inline-block;
	&__select {
		padding: 8px 15px 8px 8px;
		&--alt {
			padding: 8px 25px 8px 0px;
		}
	}
	&__menuDots {
		padding: 2px 0px 2px 5px;
		&:after {
			content: '•••';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(90deg);
			letter-spacing: 0px;
			margin-top: 0px;
			font-size: 13px;
		}
	}
	&__personAdd {
		width: 22px;
		height: 16px;
		background-image: url('../../assets/images/icons/person_add.png');
		background-size: cover;
		background-position: center center;
	}
	&__members {
		width: 22px;
		height: 16px;
		background-image: url('../../assets/images/icons/groupIcon.png');
		background-size: cover;
		background-position: center center;
	}
	&__admins {
		width: 18px;
		height: 18px;
		background-image: url('../../assets/images/icons/adminsIcon.png');
		background-size: cover;
		background-position: center center;
	}
	&__mobileMenuIcon {
		width: 16.5px;
		height: 10.5px;
		background-image: url('../../assets/images/icons/mobileMenuIcon.png');
		background-size: cover;
		background-position: center center;
	}
	&__close {
		width: 14px;
		height: 14px;
		background-image: url('../../assets/images/icons/closeIcon.png');
		background-size: cover;
		background-position: center center;
	}
	&__config {
		width: 21.3px;
		height: 22px;
		background-image: url('../../assets/images/icons/configIcon.png');
		background-size: cover;
		background-position: center center;
	}
	&__logOut {
		width: 16.5px;
		height: 18px;
		margin-left: 4.5px;
		background-image: url('../../assets/images/icons/logOutIcon.png');
		background-size: cover;
		background-position: center center;
	}
	&__delete {
		width: 19.5px;
		height: 16.5px;
		margin-left: 4.5px;
		background-image: url('../../assets/images/icons/delete.png');
		background-size: cover;
		background-position: center center;
	}
	&__edit {
		width: 19.5px;
		height: 16.5px;
		margin-left: 4.5px;
		background-image: url('../../assets/images/icons/edit.png');
		background-size: cover;
		background-position: center center;
	}
	&__sent {
		width: 19.5px;
		height: 16.5px;
		margin-left: 4.5px;
		background-image: url('../../assets/images/icons/sent.png');
		background-size: cover;
		background-position: center center;
	}
	&__lock {
		width: 19.5px;
		height: 16.5px;
		margin-left: 4.5px;
		background-image: url('../../assets/images/icons/lock.png');
		background-size: cover;
		background-position: center center;
	}
	&__download {
		width: 18px;
		height: 18px;
		margin-left: 4.5px;
		background-image: url('../../assets/images/icons/download.svg');
		background-size: cover;
		background-position: center center;
	}
	&__user {
		width: 23px;
		height: 20px;
		margin-left: 4.5px;
		background-image: url('../../assets/images/icons/user.png');
		background-size: cover;
		background-position: center center;
	}
	&__search {
		width: 23px;
		height: 20px;
		margin-left: 4.5px;
		background-image: url('../../assets/images/icons/search.png');
		background-size: cover;
		background-position: center center;
	}
	&__pause {
		width: 23px;
		height: 20px;
		margin-left: 4.5px;
		background-image: url('../../assets/images/icons/pause.png');
		background-size: cover;
		background-position: center center;
	}
	&__cancelMembership {
		width: 19px;
		height: 16.5px;
		margin-left: -2.5px;
		background-image: url('../../assets/images/icons/cancelMembership.png');
		background-size: cover;
		background-position: center center;
	}
	&__arrowDown {
		width: 9px;
		height: 5px;
		margin-left: -2.5px;
		background-image: url('../../assets/images/icons/arrowDown.svg');
		background-size: cover;
		background-position: center center;
	}
	&__rightArrow {
		width: 24px;
		height: 24px;
		margin-left: -2.5px;
		background-image: url('../../assets/images/icons/rightArrow.png');
		background-size: cover;
		background-position: center center;
	}
	&__leftArrow {
		width: 24px;
		height: 24px;
		margin-left: -2.5px;
		background-image: url('../../assets/images/icons/leftArrow.png');
		background-size: cover;
		background-position: center center;
	}
	&__switchDown {
		width: 11px;
		height: 6px;
		margin-left: 8px;
		background-image: url('../../assets/images/icons/switchLeft.png');
		background-size: cover;
		background-position: center center;
	}
	&__switchUp {
		width: 11px;
		height: 6px;
		margin-left: 8px;
		background-image: url('../../assets/images/icons/switchLeft.png');
		background-size: cover;
		background-position: center center;
		transform: rotate(180deg);
	}
	&__sortByButton {
		width: 20px;
		height: 20px;
		margin-left: 3px;
		background-image: url('../../assets/images/icons/sortByButton.png');
		background-size: cover;
		background-position: center center;
	}
}

// Variables.
:root {
  --black: #24292C;
  --secondaryBlack: #2B3033;
  --darkGrey: #454A4B;
  --grey: #939696;
  --greyTwo: #ADADAF;
  --white: #F7F7F7;
  --primary: #FF8282;
  --secondary: #dabc75;
  --tertiary: #8D9091;
  --green: #6BD45F;
  --yellow: #F2A33C;
  // Text
  --fontFamily: 'sofiapro-light', sans-serif;
  --titleFontWeight: 400;
  --titleFontSize: 17px;
  --titleLineHeight: 19.55px;
  --textFontWeight: 400;
  --textFontSize: 13px;
  --textLineHeight: 14.95px;
  --smallFontSize: 11px;
}

// Breakpoints.
$breakpoints: (
  mobile-xs: 280px,
  mobile-s: 350px,
  mobile-m: 400px,
  mobile-l: 460px,
  mobile-xl: 630px,
  tablet-xs: 720px,
  tablet-s: 768px,
  tablet-m: 820px,
  tablet-l: 990px,
  tablet-xl: 1024px,
  desktop-xs: 1180px,
  desktop-s: 1440px,
  desktop-m: 1680px,
  desktop-l: 1920px,
  desktop-xl: 2560px,
);

@mixin breakpoint($breakpoint, $direction) {
	@if map-has-key($breakpoints, $breakpoint) {
  
	  // Get the breakpoint value.
	  $breakpoint-value: map-get($breakpoints, $breakpoint);
	  
	  @if $direction == max {
		@media (max-width: ($breakpoint-value - 1)) {
		  @content;
		}
	  } @else if $direction == min {      
		@media (min-width: $breakpoint-value) {
		  @content;
		}
	  } 
	
	// If the breakpoint doesn't exist in the map.
	} @else {
	  @if $direction == max {
		@media (max-width: $breakpoint) {
		  @content;
		}
	  } @else if $direction == min {      
		@media (min-width: $breakpoint) {
		  @content;
		}
	  }  
	}
}
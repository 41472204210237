.ResetPassword {
	&__text {
		margin: 0 0 60px;
		font-size: 16px;
		line-height: 18.5px;
		&:last-of-type {
			margin: 70px 0 0;
		}
	}

	&__text-error {
		color: var(--primary);
	}
	.buttonWrapper {
		margin: 15px 0 0;
	}
	&__link {
		display: block;
		margin: 30px 0 0;
		color: var(--white);
		text-decoration: none;
	}
}

.textArea {
	position: relative;
	text-align: left;

	&__label {
		display: block;
		margin: 0 0 10px;
		font-size: 15px;
		line-height: 1;
	}

	&__inputContainer {
		position: relative;
		display: flex;
		flex-direction: column;
	}

	&__input {
		padding: 20px 12px;
		box-sizing: border-box;
		width: 100%;
		font-size: 16px;
		min-height: 96px;
		line-height: 1;
		background-color: var(--black);
		color: var(--white);
		border: 1px solid var(--darkGrey);
		font-family: var(--fontFamily) !important;
		resize: none;

		&:disabled {
			background-color: #34393b;
			border: 1px solid var(--darkGrey);
			color: var(--white);
		}
	}

	&__icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 14px;
		user-select: none;
	}

	&__input-error {
		color: var(--primary);
	}
}
.access {
	&MainBox {
		min-width: 100%;
		min-height: 100%;
		background-color: var(--black);
		display: flex;
		justify-content: center;
		text-align: center;
		align-items: center;
	}
	&Box {
		height: 20vh;
	}
	&Description {
		min-width: 100%;
		min-height: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	&Title {
		font-size: 15px;
		line-height: 17.25px;
		width: 258px;
		height: 34px;
	}
	&ButtonBox {
		min-width: 100%;
		min-height: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	&Button {
		font-family: var(--fontFamily);
		font-weight: 400;
		font-size: 17px;
		width: 96px;
		height: 44px;
        background-color: var(--black);
		border: 1px solid #fff;
		color: var(--white);        
	}
    &Button:hover {
		cursor: pointer;    
	}
}

@import '../../../assets/styles/variables';
.DashboardLayout {
	display: flex;
	min-height: 100vh;
	width: 100%;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	flex-direction: column;
	* {
		box-sizing: border-box;
	}
	@include breakpoint('desktop-xs', 'min') {
		flex-direction: row;
	}
	&__mobileMenuIcon {
		padding: 5%;
		@include breakpoint('desktop-xs', 'min') {
			display: none;
		}
	}
	&__desktopHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: var(--secondaryBlack);
		border-bottom: 1px solid var(--darkGrey);
	}
	&__mobileHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: var(--secondaryBlack);
		border-bottom: 1px solid var(--darkGrey);
	}
	&__close {
		position: absolute;
		left: 5%;
		top: 28px;
		z-index: 100;
		@include breakpoint('desktop-xs', 'min') {
			display: none;
		}
	}
	&__logo {
		width: 90%;
		max-width: 140px;
		padding: 26px 20px;
	}
	&__menu {
		height: 100%;
		background: var(--secondaryBlack);
		border-right: 1px solid var(--darkGrey);
		@include breakpoint('desktop-xs', 'max') {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 99;
		}
		&__logo {
			width: 90%;
			max-width: 140px;
			padding: 26px 20px;
			margin-left: auto;
			@include breakpoint('desktop-xs', 'min') {
				margin-left: 0;
			}
		}
		&__icon {
			padding: 16px 20px;
			&:hover{
				cursor: pointer;
				background-color: var(--darkGrey);
			}
		}
	}
	&__content {
		height: 100%;
		width: -webkit-fill-available;
		width: -moz-available;
		text-align: left;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 0 5%;
		@include breakpoint('desktop-xs', 'min') {
			padding: 0 40px;
		}
		&__header {
			@include breakpoint('desktop-xs', 'min') {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			&__title {
				margin: 18px 0 5px;
				font-size: 34px;
				font-weight: 400;
				line-height: 1.4;
				@include breakpoint('desktop-xs', 'min') {
					margin: 30px 0 5px;
				}
			}

			&__two {
				display: flex;
				align-items: center;
				gap: 16px;
				margin: 35px 0 10px;
				@include breakpoint('desktop-xs', 'max') {
					width: 100%;
					justify-content: space-between;
				}

				&__total {
					font-size: 14px;
					font-weight: 300;
					@include breakpoint('desktop-xs', 'min') {
					}
					color: var(--white);
				}
				&__division {
					background-color: var(--darkGrey);
					width: 2px;
    				height: 20px;
				}
			}
		}
	}
	--toastify-icon-color-success: var(--green);
	--toastify-icon-color-warning: var(--secondary);
	--toastify-icon-color-error: var(--primary);
	--toastify-toast-width: 530px;
	--toastify-color-dark: var(--secondaryBlack);
	--toastify-toast-min-height: 44px;
	--toastify-font-family: var(--fontFamily);
	.Toastify {
		font-family: var(--fontFamily);
		&__toast-container {
			width: 90vw;
			max-width: var(--toastify-toast-width);
			left: 50%;
			transform: translateX(-50%);
			&--bottom-center {
				bottom: 30px;
				@include breakpoint('desktop-xs', 'min') {
					bottom: -4px;
				}
			}
		}
		&__toast {
			border-radius: 0;
			border: 1px solid var(--darkGrey);
			align-items: center;
			&--success {
				border-left: 1px solid var(--green);
			}
			&--warning {
				border-left: 1px solid var(--secondary);
			}
			&--error {
				border-left: 1px solid var(--primary);
			}
		}
		&__close-button {
			align-self: center;
		}
	}
}

.AdminList {
	margin: 16px 0 0 0;

	&__role {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.ThreeDotsOptionsMenu {
			cursor: pointer;
			&__item-edit {
				width: 100%;
				cursor: pointer;
			}
		}
	}
}

.ThreeDotsOptionsMenuWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 32px;
	width: 32px;
	z-index: 3;
	&.clicked {
		background-color: var(--darkGrey);
	}
	.NobleIcon {
		cursor: pointer;
	}
	.ThreeDotsOptionsMenu {
		position: absolute;
		right: -8px;
		bottom: 100%;
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin-left: 4.5px;
		padding: 20px 25px;
		background: var(--secondaryBlack);
		border: 1px solid var(--darkGrey);
		text-align: left;
		&__item {
			margin: 0 0 10px;
			display: flex;
			align-items: center;
			color: var(--white);
			text-decoration: none;
			background-color: transparent;
			border: none;
			padding: 0;
			cursor: pointer;
			&:last-of-type {
				margin: 0;
			}
			.NobleIcon {
				margin: 0 10px 0 0;
			}
		}
	}
}

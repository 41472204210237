.UserMemberModal {
	height: 100%;

	&__title {
		margin: 0;
		padding: 25px;
		font-size: 24px;
		font-weight: 400;
		line-height: 1;
		border-bottom: 1px solid var(--darkGrey);
	}
}

@import '../../assets/styles/variables';
.inputSelect {
    
	&__label {
		display: block;
		margin: 0 0 10px;
		font-size: 14px;
		font-weight: 400;
		line-height: 1;
		color: var(--greyTwo);
	}
}
@import '../../../assets/styles/variables';
.NobelPopUpModal {
	&__Overlay {
		//background-color: transparent !important;
	}
	&__Content {
	}
	&__close {
		position: absolute;
		right: 5%;
		top: 28px;
		z-index: 100;
		cursor: pointer;
	}
}

.FormDisplayImageInvestor {
   display: flex;
   justify-content: flex-start;
   flex-direction: column;
   width: max-content;

   &__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4;
      color: var(--white);
      margin-bottom: 10px;
   }

   &__browse_photos {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      color: var(--white);
      cursor: pointer;
      text-decoration: underline;
   }

   &__legend {
      display: flex;
      max-width: 200px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 10px;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.4;
      color: var(--white);
   }
}

@import '../../assets/styles/variables';

.LoginLayout {
	width: 100%;
	@include breakpoint('tablet-xl', 'min') {
		display: flex;
	}
	&__mainBox {
		width: 90%;
		min-height: 100%;
		max-width: 400px;
		margin: 0 auto;
		background-color: var(--black);
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include breakpoint('tablet-xl', 'min') {
			flex-basis: 60%;
		}
	}
	&__logo-and-title {
		margin: 0 0 70px;
		picture {
			display: block;
			max-width: 100px;
			margin: 0 auto;
			img {
				width: 100%;
			}
		}
		p {
			margin: 12px 0 0;
			font-size: 16px;
		}
	}
	&__video {
		display: none;
		@include breakpoint('tablet-xl', 'min') {
			display: block;
			flex-basis: 40%;
			video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

.CreatePassword {
	&__form {
		width: 100%;
	}
	.inputField {
		margin: 24px 0 0;
		&:nth-child(1) {
			margin: 0;
		}
	}

	.inputError {
		text-align: left !important;
	}

	.inputHelper {
		display: block;
		margin: 5px 0 0;
		font-size: 13px;
		line-height: 15px;
		color: var(--grey);
		text-align: left;

		&.error {
			color: var(--primary);
		}
	}

	.buttonWrapper {
		margin: 20px 0 0;
	}

	&__footer {
		margin: 80px 0 0;
		font-size: 15px;
		line-height: normal;
	}
}

@import '../../../assets/styles/variables';

.UserApplicationModal {
	height: 100%;

	&__status-badge {
		position: absolute;
		top: 28px;
		right: calc(5% + 50px);
		text-transform: capitalize;

		&.waitlist {
			color: var(--yellow);
		}

		&.rejected {
			color: var(--primary);
		}
	}

	&__title {
		margin: 0;
		padding: 25px;
		font-size: 24px;
		font-weight: 400;
		line-height: 1;
		border-bottom: 1px solid var(--darkGrey);
	}

	&__form {
		min-height: calc(100% - 75px);
		display: flex;
		flex-direction: column;

		&__section {
			padding: 14px 24px 24px;
			border-top: 1px solid var(--darkGrey);
			border-bottom: 1px solid var(--darkGrey);

			&:last-of-type {
				border-bottom: none;
			}

			&.one {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;

				@include breakpoint('desktop-xs', 'max') {
					flex-direction: column-reverse;
					gap: 24px;
				}

				&>div:last-of-type {
					padding-right: 16px;

					@include breakpoint('desktop-xs', 'max') {
						padding: 0px;
					}
				}
			}

			&.two-col {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				&.referral-section {
					border: none;
					flex-wrap: nowrap;
					gap: 15px;

					.FormDisplayGroup {
						&:first-of-type {
							flex-basis: 48%;
						}
					}
				}

				.FormDisplayGroup {
					flex-basis: 48%;
				}

				.FormDisplayGroup {
					&:first-of-type {
						flex-basis: 100%;
					}
				}

				.FormDisplayGroupReferral {
					&~.FormDisplayGroup {
						margin: 0;
					}

					&:first-of-type {
						flex-basis: 48%;
					}
				}
			}

			&.buttons-section {
				width: 100%;
				margin: auto 0 0;
				display: flex;
				align-items: center;

				.buttonWrapper {
					&__btn {
						width: 100%;
					}
				}

				.ThreeDotsOptionsMenu {
					width: 210px;
					padding: 15px 20px;

					&__item {
						color: var(--primary);
					}
				}
			}

			&__left-side-btns {
				width: 92%;

				@include breakpoint('desktop-xs', 'min') {
					width: -webkit-fill-available;
					display: flex;
					align-items: center;
					gap: 15px;
				}

				.buttonWrapper {
					margin: 0 0 15px;

					&:last-of-type {
						margin: 0;
					}

					@include breakpoint('desktop-xs', 'min') {
						margin: 0;
						flex-basis: 33%;

						&.accept-btn {
							flex-basis: 33%;
						}
					}
				}

				&.not_rejected {
					width: 100%;
					justify-content: space-between;

					.buttonWrapper {
						@include breakpoint('desktop-xs', 'min') {
							margin: 0;
							flex-basis: 25%;

							&.accept-btn {
								flex-basis: 50%;
							}
						}
					}
				}
			}

			.ThreeDotsOptionsMenuWrapper {
				margin-left: auto;

				@include breakpoint('desktop-xs', 'min') {
					margin-left: 20px;
				}
			}

			.isReferral {
				display: flex;

				&__option {
					padding: 5px 22px;
					background: var(--secondaryBlack);
					border: 1px solid var(--darkGrey);
					color: var(--white);

					@include breakpoint('desktop-xs', 'min') {
						padding: 5px 30px;
					}

					&.active {
						background: var(--white);
						color: var(--black);
					}
				}
			}
		}
	}
}
.sub-loader {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
	z-index: 10;
	background-color: #24292c59;
	@keyframes ldio-kiv8subw9ue {
		0% {
			transform: translate(-50%, -50%) rotate(0deg);
		}
		100% {
			transform: translate(-50%, -50%) rotate(360deg);
		}
	}
	.ldio-kiv8subw9ue div {
		position: absolute;
		width: 20%;
		height: 20%;
		border: 3px solid var(--white);
		border-top-color: transparent;
		border-radius: 50%;
	}
	.ldio-kiv8subw9ue div {
		animation: ldio-kiv8subw9ue 1s linear infinite;
		top: 50%;
		left: 50%;
	}
	.loadingio-spinner-rolling-3nwocq145a2 {
		width: 70px;
		height: 70px;
		display: inline-block;
		overflow: hidden;
		background: var(--black);
		border-radius: 100%;
	}
	.ldio-kiv8subw9ue {
		width: 100%;
		height: 100%;
		position: relative;
		transform: translateZ(0) scale(1);
		backface-visibility: hidden;
		transform-origin: 0 0; /* see note above */
	}
	.ldio-kiv8subw9ue div {
		box-sizing: content-box;
	}
	/* generated by https://loading.io/ */
}

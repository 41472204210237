.OptionButton {
	padding: 8.5px 16px;
	background: var(--secondaryBlack);
	border: 1px solid var(--darkGrey);
	color: var(--white);
	font-size: 16px;
    font-weight: 400;
	cursor: pointer;
	span {
		opacity: 0.5;
	}
	&.active {
		background: var(--darkGrey);
		span {
			opacity: 1;
		}
	}
}

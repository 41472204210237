.MembersList {
	display: flex;
	min-height: 83.5vh;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: 1600px) {
		min-height: 80vh;
	}

	@media (max-width: 640px) {
		align-items: center;
		min-height: fit-content;
	}
	&__status {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__title {
			display: flex;
			flex-direction: column;
			gap: 4px;
			font-weight: lighter;

			> span:first-of-type {
				color: var(--white);
			}

			> span:nth-of-type(2) {
				color: var(--white);
			}
			&--warning {
				> span:first-of-type {
					color: #f2a33c;
				}
				> span:nth-of-type(2) {
					color: #f7f7f780;
				}
			}
			&--danger {
				> span:first-of-type {
					color: #ff8282;
				}
				> span:nth-of-type(2) {
					text-decoration: line-through;
					color: #f7f7f780;
				}
			}
			&--danger--two {
				> span:first-of-type {
					color: #ff8282;
				}
				> span:nth-of-type(2) {
					color: #f7f7f780;
				}
			}
		}
	}
	.line-through {
		text-decoration: line-through;
	}

	&__container_paginate {
		width: 100%;
		padding: 18px 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 16px;

		@media (max-width: 640px) {
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		& .pageIndicator {
			display: flex;
			align-items: center;
			gap: 5px;
			&__item {
				font-size: 14px;
				user-select: none;
				color: var(--white);
			}
		}

		& .pageNumber {
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			width: fit-content;

			&__btn_paginate {
				background-color: transparent;
				border: none;
				outline: none;
				padding: 0 1.5px;
				margin: 0;

				&.last_btn {
					& svg {
						transform: rotate(180deg);
					}
				}

				&.disabled {
					& svg {
						color: var(--darkGrey);
						cursor: not-allowed;
					}
				}

				& svg {
					color: var(--white);
					user-select: none;
					cursor: pointer;

					&:hover {
						color: var(--darkGrey);
						transition: all .25s ease-in-out;
					}
				}

				&:hover {
					color: var(--darkGrey);
					transition: color 0.18s ease-in-out;
				}
			}

			&__item_paginate {
				border: none;
				outline: none;
				background-color: transparent;
				color: var(--white);
				user-select: none;
				padding: 1.5px 5px;
				cursor: pointer;

				& > span {
					font-size: 15px;
				}

				&.active {
					border-radius: 48.5%;
					background-color: var(--darkGrey);
				}

				&:disabled {
					color: var(--grey);
					cursor: not-allowed;
				}
			}
		}
	}
}

::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

::-webkit-scrollbar-thumb {
	background-color: var(--darkGrey);
	border-radius: 6px;

	&:hover {
		background: #555;
	}
}

::-webkit-scrollbar-track {
	background-color: var(--grey);
	border-radius: 6px;
}

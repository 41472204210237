.DashboardNavigation {
	height: calc(100% - 72px - 70px - 30px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	border-bottom: 1px solid var(--darkGrey);
	&__item {
		width: 100%;
		display: flex;
		align-items: center;
		color: var(--white);
		text-decoration: none;
		text-align: left;
		font-weight: 300;
		padding: 16px 20px;
		&--total {
			margin: 0 0 0 auto;
		}
		&:hover{
			background-color: var(--darkGrey);
		}
		&.active {
			background-color: var(--darkGrey);
		}
		.NobleIcon {
			margin: 0 1ch 0 0;
		}

		&__section-admin {
			&:last-of-type {
				margin: auto 0 0;
				width: 100%;
			}
		}
	}
}
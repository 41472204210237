.buttonWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	&__btn {
		font-family: var(--fontFamily);
		font-weight: 400;
		font-size: 17px;
		line-height: 1;
		border: 1px solid var(--white);
		color: var(--white);
		cursor: pointer;
		&--black {
			background-color: var(--black);
		}
		&--white {
			background-color: var(--white);
			color: var(--black);
		}
		&--size-default {
			width: 96px;
			height: 44px;
		}
		&--size-full {
			width: 100%;
			padding: 13.5px;
		}
		&--disabled {
			pointer-events: none;
			opacity: 0.5;
		}
	}
}

@import '../../../assets/styles/variables';

.SubscriptionType {
	$el: &;
	$alternative-color: #2c3640;

	&__additional {
		text-align: right;
		margin-bottom: 10px;
	}

	&__price {
		align-items: center;
		background: $alternative-color;
		display: flex;
		height: 60px;
		justify-content: space-between;
		margin: 0 0 10px;
		padding: 0 20px;
		&__type {
			align-items: center;
			display: flex;
			justify-content: center;
		}

		&__tier {
			font-size: 17px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin: 0 0 0 10px;
		}
		@keyframes strike {
			0% {
				width: 0;
			}
			100% {
				width: 100%;
			}
		}
		&__amount {
			font-size: 17px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			span {
				transition: height 0ms 0ms, opacity 0.1s 0.1s ease-in;
				color: currentColor;
				&:first-child {
					margin: 0 5px 0 0;
					position: relative;

					&::after {
						content: ' ';
						position: absolute;
						top: 50%;
						left: 0;
						width: 100%;
						height: 1px;
						background: currentColor;
						animation-name: strike;
						animation-duration: 0.3s;
						animation-timing-function: linear;
						animation-iteration-count: 1;
						animation-fill-mode: forwards;
					}
				}
				&:last-child {
					visibility: visible;
					opacity: 1;
				}
			}
		}

		&--noir-acess-member,
		&--noir-access-member {
			background: #565656;
		}
		&--elite-member {
			background: #f0f0f0;
			color: #24292c;
		}
		&--noir-member {
			background: #1b1b1b;
		}
	}

	&__checkbox {
		height: 0;
		visibility: hidden;
		width: 0;
	}

	&__checkbox-label {
		align-items: center;
		cursor: pointer;
		display: flex;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		gap: 16px;
		justify-content: center;
		line-height: normal;
	}

	&__switch {
		background: $alternative-color;
		border-radius: 12px;
		font-size: 0;
		height: 24px;
		position: relative;
		transition: 0.3s;
		width: 36px;
		transition: all 0.2s ease-in;

		&:after {
			background: #fff;
			border-radius: 90px;
			content: '';
			height: 18px;
			position: absolute;
			right: 3px;
			top: 3px;
			transition: 0.3s;
			width: 18px;
		}
	}

	&__tag {
		align-items: center;
		background: $alternative-color;
		display: flex;
		height: 24px;
		justify-content: center;
		padding: 0 16px;
	}

	&--monthly {
		#{$el} {
			&__price {
				&__amount {
					span {
						&:first-child {
							margin: 0;
							text-decoration: none;
							&::after {
								content: unset;
							}
						}
						&:last-child {
							opacity: 0;
							height: 0;
							width: 0;
							overflow: hidden;
							display: inline-block;
						}
					}
				}
			}

			&__switch {
				background-color: #cccccc;
				&:after {
					transform: translateX(-12px);
				}
			}
		}
	}
}

.CustomOptionsMenu {
	display: flex;

	.ThreeDotsOptionsMenuWrapper {
		.NobleIcon {
			cursor: pointer;
		}

		.ThreeDotsOptionsMenu {
			right: 0;
			display: inline-flex;
			padding: 2px 0px;
			flex-direction: column;
			align-items: flex-start;

			&__item-delete {
				display: flex;
				align-items: center;
				color: var(--primary);
				gap: 12px;
				text-decoration: none;
				user-select: none;
				background-color: transparent;
				border: none;
				padding: 12px 20px;
				cursor: pointer;
				border-top: 1px solid var(--darkGrey);
				width: 100%;

				&:last-of-type {
					margin: 0;
				}
			}

			&__item-renew {
				display: flex;
				align-items: center;
				color: var(--white);
				gap: 10px;
				text-decoration: none;
				padding: 12px 20px 12px 10px;
				user-select: none;
				width: 100%;

				&:last-of-type {
					margin: 0;
				}
			}

			&__item-sent {
				display: flex;
				align-items: center;
				color: var(--white);
				gap: 10px;
				text-decoration: none;
				padding: 12px 20px;
				user-select: none;
				width: 100%;
				cursor: pointer;

				&:last-of-type {
					margin: 0;
				}
			}

			&__item-pause, &__item-cancel {
				display: flex;
				align-items: center;
				color: var(--white);
				gap: 10px;
				padding: 12px 20px;
				text-decoration: none;
				user-select: none;
				width: 100%;
				cursor: pointer;

				&:last-of-type {
					margin: 0;
				}
			}
			&__item-cancel{
				&:last-of-type {
					margin: 0 0 0 8px;
				}
			}

			&__item-edit {
				display: flex;
				align-items: center;
				color: var(--white);
				gap: 12px;
				padding: 12px 20px;
				text-decoration: none;
				user-select: none;
				width: 100%;

				&:last-of-type {
					margin: 0;
				}
			}
		}
	}
}

@import '../../../../assets/styles/variables';

.EditMemberInfo {
	min-width: 90%;

	&__form {
		min-height: calc(100% - 75px);

		&__section {
			display: flex;
			flex-direction: column;
			padding: 14px 36px 24px 24px;
			gap: 24px;
			border-bottom: 1px solid var(--darkGrey);

			&:last-of-type {
				border-bottom: none;
			}

			.inputField {
				&__input {
					background-color: transparent;
					color: var(--white);

					&:disabled {
						background-color: var(--darkGrey);
						cursor: not-allowed;
						color: var(--white);
					}
				}
				&__input-error {
					color: var(--primary);
					border-color: var(--primary);;
				}
			}

			.inputPhoneNumber {
				&__input {
					background-color: transparent;
				}
			}

			.textArea {
				&__input {
					background-color: var(--secondaryBlack);
				}
			}

			.customDatePicker {
				&:nth-child(1) {
					margin: 0;
				}

				>label {
					display: block;
					margin: 0 0 10px;
					font-size: 15px;
					line-height: 1;
				}
			}

			& .second-form {
				display: grid;
				grid-template-columns: auto;

				&__area-two {
					display: flex;
					justify-content: space-between;
					gap: 24px;

					.inputField,
					.InputDisabled {
						flex-basis: 50%;
					}

					&__select {
						flex-basis: 50%;
						margin: 0;
						padding-bottom: 0px;

						.NobleSelect {
							margin: 0;
							padding: 0;
						}
					}

					@include breakpoint('desktop-xs', 'max') {

						&:first-of-type,
						&:nth-child(2) {
							flex-direction: column;
						}
					}
				}
			}

			&.two-col {
				flex-direction: row;
				justify-content: space-between;
				gap: 24px;

				.inputField {
					flex-basis: 50%;
				}

				@include breakpoint('desktop-xs', 'max') {
					flex-direction: column;
				}
			}

			.section-dual {
				display: grid;
				grid-template-columns: 47% auto;
				align-items: center;
				gap: 24px;
			}

			.special-section-dual {
				display: grid;
				grid-template-columns: 47% auto;
				gap: 24px;			
			}

			.isReferral {
				display: flex;

				&__option {
					padding: 5px 22px;
					background: var(--secondaryBlack);
					border: 1px solid var(--white);
					color: var(--white);

					@include breakpoint('desktop-xs', 'min') {
						padding: 5px 30px;
					}

					&.active {
						background: var(--white);
						color: var(--black);
					}
				}
			}

			&.buttons_section {
				padding: 14px 36px 0 24px;
				margin-bottom: 20px;
				gap: 24px;

				@include breakpoint('desktop-xs', 'min') {
					justify-content: space-between;
					flex-direction: row;

					.buttonWrapper {
						&__btn {
							font-weight: 700;
						}

						&.save {
							width: 250px;

							@include breakpoint('mobile-xl', 'max') {
								width: 100%;
							}
						}

						&.cancel {
							width: 200px;

							@include breakpoint('mobile-xl', 'max') {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
.inputPhoneNumber {
	position: relative;
	text-align: left;
	&__label {
		display: block;
		margin: 0 0 10px;
		font-size: 15px;
		line-height: 1;
	}
	&__input {
		display: block;
		padding: 8px 10px;
		box-sizing: border-box;
		width: 100%;
		font-size: 16px;
		line-height: 1;
		background-color: var(--black);
		color: #cccccc;
		border: 1px solid var(--darkGrey);
	}
	&__input-error {
		color: var(--primary);
		border-color: var(--primary);
	}
}

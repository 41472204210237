@import '../../../assets/styles/variables';

.inputEditProfile {
    &__data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 25px 0;

        & span {
            color: var(--grey);
        }
    }

    &__btn {
        background: transparent;
        border: none;
        color: var(--white);

        &:hover {
            cursor: pointer;
        }
    }
}